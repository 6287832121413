/*
 * This file contains the configuration of monkey patching done by zonejs
 * It has to be included by polyfills.ts - the logic cannot be directly inside polyfills.ts
 * See ZoneGlobalConfigurations interface of the zone package for configuration
 */

(window as any).__zone_symbol__UNPATCHED_EVENTS = [
  /*
   * mouse and pointer events - the presence of these here can break angular material tooltips
   * we could also remove "pointerevent" which is fired all the time, but it has strange consequences
   * code inside runOutsideAngular is not always executed
   */
  'mouseover',
  'mousemove',
  'mouseout',
  'scroll',
  'mousewheel',
];

(window as any).__Zone_disable_requestAnimationFrame = true;
(window as any).__Zone_enable_cross_context_check = true;
(window as any).__Zone_disable_IE_check = true;
(window as any).__Zone_disable_XHR = true;

/*
 * ZoneJS patches defineProperty and with it also the whole Object api
 * including Object.create function. We are using Object.create in order to
 * use vessel-specs as prototype for items in datasets - that means we will
 * call Object.create a lot
 */

// The patch itself seems useless and we can disable it (https://github.com/angular/angular/issues/37432)
(window as any).__Zone_disable_defineProperty = true;

/*
 * NOTE Promises have to be patched, Angular cannot work if promisses are not patched
 * This option exists inside ZoneJS but it is usefull only if used without Angular
 * (window as any).__Zone_disable_ZoneAwarePromise = true;
 */
